import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import LinkArrowSvg from "../../images/link-arrow";
import { Link } from 'gatsby';
import ServiceStaticTabs from '../service/ServiceStaticTabs';

const FindUs = () => { 
    const tabs = [
        {
            link: "/ui-ux-agency-ahmedabad/",
            text: "Ahmedabad",
        },
        {
          link: "/ui-ux-agency-surat/",
          text: "Surat",
        },
        {
            link: "/ui-ux-agency-mumbai/",
            text: "Mumbai",
        },
        {
            link: "/ui-ux-agency-bangalore/",
            text: "Bangalore",
        },
        {
            link: "/ui-ux-agency-gurgaon/",
            text: "Gurgaon",
        },
        {
            link: "/ui-ux-design-agency-dubai/",
            text: "Dubai",
        },
        {
            link: "/ui-ux-design-agency-chicago/",
            text: "Chicago",
        },
        {
            link: "/ui-ux-design-company-usa/",
            text: "USA",
        }
    ];
    return (
        <section className="lg:py-[200px] md:py-[120px] py-[80px] bg-[#121212] text-white relative w-full overflow-hidden z-[1]">
            <StaticImage placeholder="none" src='../../images/guides-find-us.webp' alt='grid' className='!absolute right-0 top-0 lg:w-[auto] md:w-[40%] w-[90%] md:!block !hidden z-[-1]' />
            <StaticImage placeholder="none" src='../../images/guides-find-us-mobile.webp' alt='grid' className='!absolute right-0 top-0 md:!hidden w-[90%] z-[-1]' />
            <div className='container'>
                <h2 className='font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] md:mb-[60px] mb-[40px]'>
                    Find Us
                </h2>
                <p className='text-[#53D4B5] lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] font-light mb-[15px] lg:mb-[20px]'>Head Office</p>
                <div className='flex md:items-center justify-between flex-col md:flex-row mb-[20px] lg:mb-[30px]'>
                    <div className='flex flex-col lg:flex-row lg:gap-[80px] gap-[40px] md:gap-[60px] w-full justify-between'>
                        <div className='max-w-[530px] w-full'>
                            <h3 className='font-heading font-medium mb-[10px] md:text-[28px] text-[20px] lg:text-[35px] leading-[27px] md:leading-[40px] lg:leading-[55px]'>India</h3>
                            <p className='lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] font-light'>306, North Plaza, Nr. 4D Square Mall, Motera, Ahmedabad – 380005</p>
                        </div>
                        <div className='max-w-[500px] flex flex-col md:flex-row gap-[40px] lg:gap-[50px] w-full lg:justify-between'>
                            <div>
                                <h3 className='font-heading font-medium mb-[10px] md:text-[28px] text-[20px] lg:text-[35px] leading-[27px] md:leading-[40px] lg:leading-[55px]'>Phone</h3>
                                <a className='lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] font-light' href="tel:+918511155388">+91 85111 55388</a>
                            </div>
                            <div>
                                <h3 className='font-heading font-medium mb-[10px] md:text-[28px] text-[20px] lg:text-[35px] leading-[27px] md:leading-[40px] lg:leading-[55px]'>Email</h3>
                                <a className='lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] font-light' href="mailto:contact@octet.design">contact@octet.design</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to='/ui-ux-design-agency-india/' title={`Know More about India Office`} className="md:mb-[60px] lg:mb-[100px] mb-[40px] font-body service-link transition-all hover:duration-300 duration-300 text-[15px] font-light hover:font-normal leading-[1.2] tracking-[.03em] uppercase flex items-center gap-[9px] hover:gap-[20px]">
                    Know More <span className='rotate-45'><LinkArrowSvg size={15.58} fill='#fff' /></span>
                </Link>
                <p className='text-[#53D4B5] lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] font-light mb-[15px] lg:mb-[20px]'>Other Offices</p>
                <ServiceStaticTabs
                    tabs={tabs}
                />
                {/* <div className='flex flex-col lg:flex-row gap-[40px] lg:gap-[50px] w-full justify-between'>
                    <div className="lg:min-w-[254px]">
                        <h3 className='font-heading font-medium mb-[10px] md:text-[28px] text-[20px] lg:text-[35px] leading-[27px] md:leading-[40px] lg:leading-[55px]'>Surat</h3>
                        <p className='lg:mb-[30px] mb-[20px] lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] font-light'>227, Green Palladia,Near Palanpur Canal Rd,<br /> Surat, Gujarat, <br /> 395005</p>
                        <Link to='/ui-ux-agency-surat/' title={`Know More about Ahmedabad Office`} className="] font-body service-link transition-all hover:duration-300 duration-300 text-[15px] font-light hover:font-normal leading-[1.2] tracking-[.03em] uppercase flex items-center gap-[9px] hover:gap-[20px]">
                            Know More <span className='rotate-45'><LinkArrowSvg size={15.58} fill='#fff' /></span>
                        </Link>
                    </div>
                    <div className="lg:min-w-[254px]">
                        <h3 className='font-heading font-medium mb-[10px] md:text-[28px] text-[20px] lg:text-[35px] leading-[27px] md:leading-[40px] lg:leading-[55px]'>Mumbai</h3>
                        <p className='lg:mb-[30px] mb-[20px] lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] font-light'>402, Town Centre 2, Opp Times Square, Marol, Andheri (E), Mumbai <br /> 400059</p>
                        <Link to='/ui-ux-agency-mumbai/' title={`Know More about Ahmedabad Office`} className="] font-body service-link transition-all hover:duration-300 duration-300 text-[15px] font-light hover:font-normal leading-[1.2] tracking-[.03em] uppercase flex items-center gap-[9px] hover:gap-[20px]">
                            Know More <span className='rotate-45'><LinkArrowSvg size={15.58} fill='#fff' /></span>
                        </Link>
                    </div>
                    <div className="lg:min-w-[259px]">
                        <h3 className='font-heading font-medium mb-[10px] md:text-[28px] text-[20px] lg:text-[35px] leading-[27px] md:leading-[40px] lg:leading-[55px]'>Bangalore</h3>
                        <p className='lg:mb-[30px] mb-[20px] lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] font-light'>WeWork Cherry Hills, Embassy Golf Links Business Park, Challaghatta, Bengaluru - 560071</p>
                        <Link to='/ui-ux-agency-bangalore/' title={`Know More about Ahmedabad Office`} className="] font-body service-link transition-all hover:duration-300 duration-300 text-[15px] font-light hover:font-normal leading-[1.2] tracking-[.03em] uppercase flex items-center gap-[9px] hover:gap-[20px]">
                            Know More <span className='rotate-45'><LinkArrowSvg size={15.58} fill='#fff' /></span>
                        </Link>
                    </div>
                    <div className="lg:min-w-[254px]">
                        <h3 className='font-heading font-medium mb-[10px] md:text-[28px] text-[20px] lg:text-[35px] leading-[27px] md:leading-[40px] lg:leading-[55px]'>Gurgaon</h3>
                        <p className='lg:mb-[30px] mb-[20px] lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] font-light'>WeWork BlueOne Square,  246, Phase IV, Udyog Vihar, Gurgaon <br /> 122016</p>
                        <Link to='/ui-ux-agency-gurgaon/' title={`Know More about Ahmedabad Office`} className="] font-body service-link transition-all hover:duration-300 duration-300 text-[15px] font-light hover:font-normal leading-[1.2] tracking-[.03em] uppercase flex items-center gap-[9px] hover:gap-[20px]">
                            Know More <span className='rotate-45'><LinkArrowSvg size={15.58} fill='#fff' /></span>
                        </Link>
                    </div>
                </div> */}
            </div>
        </section>
    )
}
export default FindUs
