import * as React from "react";
// import KnowMoreSection from "../components/KnowMoreSection";
import LetsConnect from "../components/LetsConnect";
import FindUs from "../components/contact/FindUs";
import ContactForm from "../components/contact/ContactForm";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
const ContactUs = () => {
  const info = {
    title: "Want to work with our Creative Squad?",
    link: "/careers/",
    text: "Join Our Team",
    linkTitle: "Careers",
  };
  return (
    <Layout>
      <div className="contact">
        <section className="contact-banner lg:py-[120px] md:py-[100px] py-[80px] relative w-full overflow-hidden z-[1]">
          <StaticImage
            placeholder="none"
            src="../images/contact-guide.webp"
            alt="guides"
            className="!absolute right-0 bottom-0 z-[-1] lg:w-[auto] md:w-[38%] w-[83%]"
          />
          <div className="container fade-in-animation">
            <div className="first-comesup md:mb-[80px] lg:mb-[90px] mb-[62px]">
              <p className="lg:text-[60px] md:text-[49px] text-[38px] md:leading-[68px] font-semibold leading-[52px] lg:leading-[84px] font-heading lg:mb-[30px] mb-[20px]">
                {/* Let’s Collaborate to <br />
                            Create an Impact */}
                Let's begin <br /> with a conversation
              </p>
              <h1 className="lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] link-text-para">
                Fill out the form below or{" "}
                <a
                  href="https://calendly.com/sajan_deokar/30min"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  schedule the meeting{" "}
                </a>
                , at your convenience.
              </h1>
            </div>
            <ContactForm />
          </div>
        </section>
        <FindUs />
        <LetsConnect data={info} />
        {/* <KnowMoreSection Include="contact" /> */}
      </div>
    </Layout>
  );
};
export default ContactUs;
export const Head = () => (
  <>
    <Seo
      title="Contact Us | Get Started with Octet Design Studio"
      description="If you have any queries regarding our services or want to start a project, contact us by filling the form or call us on +918511188998 anytime."
    />

    <script>{`
        var x = 0;
        var timeInt = setInterval(function(){
        if(x == 0){
          if ((document.querySelectorAll('[class*="ReactModal__Content ReactModal__Content--after-open"]').length> 0 && document.querySelector('[class*="ReactModal__Content ReactModal__Content--after-open"]').innerText.indexOf('Done')!=-1) && (window.location.href.indexOf('/contact-us/')!=-1))
          {
       gtag('event', 'conversion', {'send_to': 'AW-11104773944/aitTCOHokI8YELjOla8p'});
            clearInterval(timeInt);
             x = 1;
          }
          }
        },2000);  `}</script>
  </>
);
