import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import Modal from "react-modal";
import Success from "../../images/success.gif";
import loadingGif from "../../videos/loading-submit.gif";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const chips = [
    {
      title: "Organisation",
      chips: [
        "Start-up",
        "Small Medium Business",
        "Enterprise",
        "At Idea Stage",
      ],
    },
    {
      title: "Interested In",
      chips: [
        "UI UX Design",
        "Design Audit",
        "User Research",
        "Dedicated Designers",
        "Frontend Development",
        "Branding",
        "Usability Testing",
      ],
    },
  ];
  const [activeItems, setActiveItems] = useState([[0], [0]]);
  const [oraganisation, setOraganisation] = useState(0);
  const [interested, setInterested] = useState(['UI UX Design']);
  const handleItemClick = (parentIndex, item, e) => {
    e.preventDefault();
    const updatedActiveItems = [...activeItems];
    if (parentIndex === 0) {
        updatedActiveItems[parentIndex] = [item];
        setOraganisation(item)
    } else {
      if (!updatedActiveItems[parentIndex]) {
        updatedActiveItems[parentIndex] = [item];
      } else {
        const index = updatedActiveItems[parentIndex].indexOf(item);
        if (index === -1) {
          updatedActiveItems[parentIndex].push(item);
        } else {
          updatedActiveItems[parentIndex].splice(index, 1);
        }
      }
      const indices = updatedActiveItems[1];
      const data = [
        "UI UX Design",
        "Design Audit",
        "User Research",
        "Dedicated Designers",
        "Frontend Development",
        "Branding",
        "Usability Testing",
      ];
      const filteredData = indices.map((index) => data[index]);
      setInterested(filteredData, "filteredData");
    }
    setActiveItems(updatedActiveItems);
  };

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const handleChangeName = (event) => {
    const inputValue = event.target.value;
    const regex = /^[A-Za-z\s]+$/;
    if (regex.test(inputValue) || inputValue === "") {
      setName(inputValue);
    }
  };
  const handleInputChangeNumber = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9+()\s-]*$/;
    if (regex.test(inputValue) || inputValue === "") {
      setNumber(inputValue);
    }
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
    setTimeout(() => {
      setIsPopupOpen(false);
    }, 5000);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  const [errorFeilds, setErrorFeilds] = useState(false);
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isVerified) {
      // reCAPTCHA not verified, show an error or prevent form submission
      console.log("Please complete the reCAPTCHA verification.");
      setError(true);
      return;
    } else {
      if(interested.length === 0) {
        setErrorFeilds(true)
      } else {
        setLoading(true);
        emailjs
          .sendForm(
            "service_13oh6y7",
            "template_j2akjzh",
            form.current,
            "X49fzMHS7L3Pb95EL"
          )
          .then((res) => {
            handleOpenPopup();
            setLoading(false);
          })
          .catch((err) => console.log(err));
        e.target.reset();
      }
    }
  };
  const handleRecaptchaChange = (value) => {
    // value will be null if reCAPTCHA is not verified
    setVerified(!!value);
  };
  console.log(oraganisation, interested, activeItems, 'l')
  return (
    <div className="w-[full] second-comesup">
      <Modal
        isOpen={isPopupOpen}
        onRequestClose={handleClosePopup}
        contentLabel="Custom Popup"
      >
        <div className="md:w-[444px] md:h-[420px] w-[85vw] h-[fit-content] px-[26px] lg:py-[70px] py-[45px] bg-[#FFF] items-center flex flex-col md:gap-[20px] gap-[5px] text-center">
          <img
            src={Success}
            alt="success"
            className="md:w-[150px] md:h-[150px] w-[100px] h-[100px]"
          />
          <div>
            <h2 className="lg:text-[35px] md:text-[22px] text-[20px] tracking-[.08em] lg:font-medium font-medium lg:tracking-[.06em] font-heading leading-[1.3181] lg:leading-[1.5714] mb-[5px]">
              Done!
            </h2>
            <p className="md:text-[15px] text-[13px] lg:leading-[25px] leading-[22px] tracking-[.03em] font-medium">
              Your information has been submitted. Our executive will get back
              to you within 2 hours.
            </p>
          </div>
        </div>
      </Modal>
      <form
        className="lg:w-[717px] w-[100%]"
        onSubmit={handleSubmit}
        ref={form}
      >
        <div className="flex gap-x-[30px] items-center flex-wrap">
          <input
            type="text"
            id="name"
            name="name"
            className="bg-transparent md:w-[calc(50%-15px)] w-[100%] mb-[40px] pb-[22px] md:pb-[20px] focus:border-0 focus:border-b-[2px] focus:outline-0 border-b-[1px] border-[#000] text-[#2A2A2A] lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em]"
            placeholder="Your Name"
            value={name}
            required
            onInput={handleChangeName}
          />
          <input
            type="tel"
            id="number"
            name="number"
            className="bg-transparent md:w-[calc(50%-15px)] w-[100%] mb-[40px] pb-[20px] focus:border-0 focus:border-b-[2px] focus:outline-0 border-b-[1px] border-[#000] text-[#2A2A2A] lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em]"
            placeholder="Your Number"
            value={number}
            required
            minLength={10}
            maxLength={10}
            onInput={handleInputChangeNumber}
          />
          <input
            required
            type="email"
            id="email"
            name="email"
            className="bg-transparent w-[100%] mb-[40px] pb-[20px] focus:border-0 focus:border-b-[2px] focus:outline-0 border-b-[1px] border-[#000] text-[#2A2A2A] lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em]"
            placeholder="Your Email"
          />
          <input
            type="text"
            id="organisation"
            name="organisation"
            className="bg-transparent hidden w-[100%] mb-[40px] pb-[20px] focus:border-0 focus:border-b-[2px] focus:outline-0 border-b-[1px] border-[#000] text-[#2A2A2A] lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em]"
            placeholder="Your organisation"
            readOnly
            value={
              oraganisation === -1
                ? ""
                : oraganisation === 0
                ? "Start-up"
                : oraganisation === 1
                ? "Small Medium Business"
                : oraganisation === 2
                ? "Enterprise"
                : "At Idea Stage"
            }
          />
          <input
            type="text"
            id="interested"
            name="interested"
            className="bg-transparent hidden w-[100%] mb-[40px] pb-[20px] focus:border-0 focus:border-b-[2px] focus:outline-0 border-b-[1px] border-[#000] text-[#2A2A2A] lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em]"
            placeholder="Your interest"
            readOnly
            value={interested}
          />
        </div>
        {chips.map((chip, outerIndex) => (
          <div className="mb-[40px]" key={outerIndex}>
            <p className="lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] mb-[15px]">
              {chip.title}
            </p>
            <div className="flex gap-[15px] flex-wrap chips">
              {chip.chips.map((chipText, index) => (
                <a
                  href="/"
                  key={index}
                  onClick={(e) => handleItemClick(outerIndex, index, e)}
                  className={`chip py-[6px] px-[15px] rounded-[3px] border hover:border-[#2A2A2A] border-solid bg-[white] border-[#707070] hover:text-[#2A2A2A] lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] cursor-pointer select-none ${
                    activeItems[outerIndex] &&
                    activeItems[outerIndex].includes(index)
                      ? "!border-2 border-[#2A2A2A] text-[#2A2A2A] bg-[#F2F6F6]"
                      : "text-[#7A7A7A]"
                  }`}
                >
                  {chipText}
                </a>
              ))}
            </div>
            {outerIndex === 1 && errorFeilds &&
              <p className="lg:text-[16px] md:leading-[30px] md:tracking-[.03em] md:text-[13px] text-[12px] leading-[26px] tracking-[.02em] text-[red] mb-[10px]">Please select atleast 1 service !!</p>
            }
          </div>
        ))}
        <div className="mb-[20px] lg:mb-[30px]">
          <ReCAPTCHA
            sitekey="6Lfh6XwnAAAAAHjQjcClfqb1OpVkqnMjQKfOTMd0"
            onChange={handleRecaptchaChange}
          />
        </div>
        {error ? (
          <p className="lg:text-[14px] md:leading-[30px] md:tracking-[.03em] md:text-[13px] text-[12px] leading-[26px] tracking-[.02em] text-[red] mb-[10px]">
            *Please complete the reCAPTCHA verification
          </p>
        ) : (
          ""
        )}
        <button
          type="submit"
          className={`hover:transition-all hover:duration-300 flex items-center transition-all duration-300 lg:py-[15px] md:py-[12px] py-[8px] hover:bg-[#43C5A5] rounded-[3px] ${
            loading ? "pr-[40px]" : ""
          } px-[50px] font-medium bg-[#53D5B5] text-[#2A2A2A] lg:text-[18px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[30px] tracking-[.02em]`}
        >
          {loading ? "Sending" : "Send"}{" "}
          {loading ? (
            <img className="loading-gif" src={loadingGif} alt="sending" />
          ) : (
            ""
          )}
        </button>
      </form>
    </div>
  );
};
export default ContactForm;